









import {Component, Vue} from "vue-property-decorator";
import {User} from "@/Interfaces/sessionInterface";

@Component({
    components: {}
})
export default class RegisterUser extends Vue {
  name:string=''

  mounted(){
    this.$store.watch((state, getters) => getters.userData,(value:User|undefined) => {
      if (value) {
        {
          this.name =value.name
        }
      }
    })
  }

  register(){
    this.$store.dispatch('register',this.name)
    this.$router.push(`/${this.$store.state.sessionId}/user`)
  }
}
