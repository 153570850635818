
















import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";

@Component({
  components: {}
})
export default class scene0 extends Vue {
  @Prop({required:true})role!: Roles

  get adminQrCode(){
    return `https://chart.googleapis.com/chart?cht=qr&chl=${window.location.origin +'/'+encodeURI(this.$store.state.sessionId)}/admin&chs=300x300`
  }
}
