






import {Component, Vue} from "vue-property-decorator";
import scenes from "@/components/scenes/scenes";

@Component({
  components: Object.assign(scenes,{

  })
})
export default class UserView extends Vue {

}
